var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "subtask-container",
      class: {
        invalid: _vm.is_invalid,
        excluded: _vm.render_excluded,
        expanded: _vm.show_notes,
      },
    },
    [
      _c(
        "el-form",
        { ref: "subtask_form", attrs: { model: _vm.form } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 5 } },
            [
              _c("el-col", { attrs: { span: _vm.desc_width } }, [
                _c(
                  "div",
                  { staticClass: "row description" },
                  [
                    _vm.is_estimate_draft
                      ? _c("div", { staticClass: "subtaskGrip" }, [
                          _vm._v(" ⣿ "),
                        ])
                      : _vm._e(),
                    _vm.jira_category_options.length === 1
                      ? _c("svgicon", {
                          staticClass: "jira-icon",
                          attrs: {
                            name: _vm.ISSUE_ICONS[_vm.form.jira_category],
                          },
                        })
                      : _c(
                          "div",
                          [
                            _c(
                              "el-dropdown",
                              {
                                staticClass: "el-dropdown-link",
                                attrs: {
                                  trigger: "click",
                                  placement: "bottom",
                                },
                                on: { command: _vm.changeCategory },
                                scopedSlots: _vm._u([
                                  {
                                    key: "dropdown",
                                    fn: function () {
                                      return [
                                        _c(
                                          "el-dropdown-menu",
                                          _vm._l(
                                            _vm.jira_category_options,
                                            function (value) {
                                              return _c(
                                                "el-dropdown-item",
                                                {
                                                  key: value,
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "row",
                                                    "align-items": "center",
                                                  },
                                                  attrs: { command: value },
                                                },
                                                [
                                                  _c("svgicon", {
                                                    staticStyle: {
                                                      "margin-right": "5px",
                                                    },
                                                    attrs: {
                                                      name:
                                                        _vm.ISSUE_ICONS[value],
                                                      width: "20px",
                                                      height: "20px",
                                                    },
                                                  }),
                                                  _c("span", [
                                                    _vm._v(_vm._s(value)),
                                                  ]),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("svgicon", {
                                      staticClass: "jira-icon",
                                      attrs: {
                                        name:
                                          _vm.ISSUE_ICONS[
                                            _vm.form.jira_category
                                          ],
                                      },
                                    }),
                                    _vm.form.jira_category ===
                                    _vm.JIRA_CATEGORIES.DEFAULT
                                      ? _c("div", {
                                          directives: [
                                            {
                                              name: "loading",
                                              rawName: "v-loading",
                                              value: _vm.detecting,
                                              expression: "detecting",
                                            },
                                          ],
                                          staticClass: "spinner",
                                          attrs: {
                                            "element-loading-spinner":
                                              "el-icon-loading",
                                            "element-loading-background":
                                              "rgba(0, 0, 0, 0)",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                    _c(
                      "el-input",
                      {
                        ref: "description",
                        staticClass: "input-description",
                        class: {
                          empty: _vm.isEmpty(_vm.form.description),
                        },
                        attrs: {
                          size: "small",
                          placeholder: "Subtask description",
                          readonly: _vm.readonly,
                        },
                        on: { change: _vm.autoDetectCategory },
                        nativeOn: {
                          keyup: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            return (() =>
                              _vm.enterOnField("description")).apply(
                              null,
                              arguments
                            )
                          },
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      },
                      [
                        _c(
                          "el-tooltip",
                          {
                            attrs: { slot: "suffix", placement: "top" },
                            slot: "suffix",
                          },
                          [
                            _c(
                              "div",
                              { attrs: { slot: "content" }, slot: "content" },
                              [
                                _c("strong", [_vm._v("Subtasks")]),
                                _vm._v(
                                  " define individual work units for a task."
                                ),
                                _c("br"),
                                _c("br"),
                                _vm._v(" Subtasks are converted to "),
                                _c("strong", [_vm._v("Jira subtasks")]),
                                _c("br"),
                                _vm._v("when the estimate is accepted. "),
                              ]
                            ),
                            _c("span", { staticClass: "info-icon" }, [
                              _vm._v("?"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    !_vm.readonly || _vm.has_notes
                      ? _c(
                          "div",
                          {
                            staticClass: "notes-button",
                            class: {
                              visible: _vm.show_notes,
                              content: _vm.has_notes,
                            },
                            on: { click: _vm.toggleShowNotes },
                          },
                          [
                            _c("i", {
                              class: _vm.has_notes
                                ? "el-icon-document"
                                : "el-icon-document-add",
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _vm.is_estimate_draft
                ? _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("el-input-number", {
                          ref: "min",
                          staticClass: "input-value",
                          class: {
                            empty: _vm.isEmpty(_vm.form.min),
                          },
                          attrs: {
                            controls: false,
                            size: "small",
                            placeholder: "min",
                            min: 0,
                          },
                          on: {
                            change: _vm.handleBlurMinMax,
                            focus: _vm.selectInput,
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return (() => _vm.enterOnField("min")).apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.form.min,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "min", $$v)
                            },
                            expression: "form.min",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.is_estimate_draft
                ? _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("el-input-number", {
                          ref: "max",
                          staticClass: "input-value",
                          class: {
                            empty: _vm.isEmpty(_vm.form.max),
                          },
                          attrs: {
                            controls: false,
                            size: "small",
                            placeholder: "max",
                            min: 0,
                          },
                          on: {
                            change: _vm.handleBlurMinMax,
                            focus: _vm.selectInput,
                          },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return (() => _vm.enterOnField("max")).apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.form.max,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "max", $$v)
                            },
                            expression: "form.max",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _c("el-col", { attrs: { span: 2 } }, [
                _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("el-input-number", {
                      ref: "qty",
                      staticClass: "input-value",
                      class: {
                        empty: _vm.isEmpty(_vm.form.qty),
                      },
                      attrs: {
                        controls: false,
                        size: "small",
                        placeholder: "qty",
                        min: 0,
                      },
                      on: { change: _vm.handleBlurQty, focus: _vm.selectInput },
                      nativeOn: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return (() => _vm.enterOnField("qty")).apply(
                            null,
                            arguments
                          )
                        },
                      },
                      model: {
                        value: _vm.form.qty,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "qty", $$v)
                        },
                        expression: "form.qty",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              !_vm.is_estimate_draft
                ? _c("el-col", { attrs: { span: 2 } }, [
                    _c(
                      "div",
                      { staticClass: "row" },
                      [
                        _c("el-input-number", {
                          ref: "input_override_qty",
                          staticClass: "input-value",
                          attrs: {
                            precision: 2,
                            controls: false,
                            size: "small",
                            min: 0,
                            placeholder: parseFloat(_vm.form.qty).toFixed(2),
                          },
                          on: { focus: _vm.selectInput },
                          nativeOn: {
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return (() =>
                                _vm.enterOnField("input_override_qty")).apply(
                                null,
                                arguments
                              )
                            },
                          },
                          model: {
                            value: _vm.override.qty,
                            callback: function ($$v) {
                              _vm.$set(_vm.override, "qty", $$v)
                            },
                            expression: "override.qty",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.is_days
                ? _c("el-col", { attrs: { span: 2 } }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "span",
                        {
                          staticClass: "el-input__inner input-value read-only",
                          class: { adjusted: _vm.isNumeric(_vm.adjustment) },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("hours2duration")(_vm.adjusted_hours)
                                ) +
                                " "
                            ),
                          ]),
                          _vm.isNumeric(_vm.adjustment)
                            ? _c(
                                "span",
                                {
                                  staticClass: "adjustment",
                                  class: { negative: _vm.hours_difference < 0 },
                                },
                                [
                                  _vm._v(
                                    " (" +
                                      _vm._s(
                                        _vm._f("hours2duration")(
                                          _vm.hours_difference
                                        )
                                      ) +
                                      ") "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _c("el-col", { attrs: { span: _vm.options_width } }, [
                !_vm.readonly && _vm.can_delete
                  ? _c("div", { staticClass: "options-col" }, [
                      _vm.is_dirty
                        ? _c("div", {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: true,
                                expression: "true",
                              },
                            ],
                            staticClass: "option-icon spinner",
                            attrs: {
                              "element-loading-spinner": "el-icon-loading",
                              "element-loading-background": "rgba(0, 0, 0, 0)",
                            },
                          })
                        : _c(
                            "div",
                            {
                              staticClass: "option-icon delete",
                              class: { disabled: !_vm.can_delete },
                              on: { click: _vm.handleDelete },
                            },
                            [_c("i", { staticClass: "el-icon-close" })]
                          ),
                    ])
                  : !_vm.is_estimate_locked
                  ? _c("div", { staticClass: "options-col right" }, [
                      _c(
                        "div",
                        {
                          staticClass: "exclude-icon",
                          class: {
                            excluded: _vm.is_excluded,
                          },
                          on: { click: _vm.handleExclude },
                        },
                        [_c("i", { staticClass: "el-icon-circle-close" })]
                      ),
                    ])
                  : _vm._e(),
              ]),
            ],
            1
          ),
          _vm.show_notes
            ? _c(
                "div",
                {
                  staticClass: "notes",
                  class: { draft: _vm.is_estimate_draft },
                },
                [
                  _c("text-editor", {
                    attrs: {
                      theme: "dark",
                      editable: !_vm.readonly,
                      code_block: false,
                      fancy_links: false,
                      content: _vm.form.notes,
                    },
                    on: {
                      "update:content": [
                        function ($event) {
                          return _vm.$set(_vm.form, "notes", $event)
                        },
                        _vm.handleUpdateNotes,
                      ],
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }